<template>
    <div class="refillDetail">
        <div class="PageCommon">
            <!-- <h3 class="pageTitle">
                {{ $t('MyFollowers_OperationRecord') }}
            </h3> -->
            <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
            <p class="closeTips">
               {{ $t('refillcloseAllOrderTips') }} 
            </p>
            <div class="refillDetail_title" v-if="!mobileFlag">
                <el-breadcrumb separator-class="el-icon-arrow-left">
                    <el-breadcrumb-item class="pageTitle" :to="{ path: '/MyAccount' }">{{ refillMainAccount
                    }}</el-breadcrumb-item>
                    <el-breadcrumb-item class="pageTitle">{{ refillSubAccount }}</el-breadcrumb-item>
                </el-breadcrumb>
                <div>
                    <el-button class="confirmDialogBtn" @click="back()" icon="el-icon-back">
          {{
              $t("MyFollowers_Back")
          }}</el-button>
           <el-button class="confirmDialogBtn" @click="refresh()" icon="el-icon-refresh-right">
          {{
              $t("ManualReplenishment_refresh")
          }}</el-button>
                </div>
    
            </div>


            <div class="tableDiv" v-if="!mobileFlag">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="equity" :label="$t('ManualReplenishment_Equity')">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.equity ? formatNumberFractionDigits(scope.row.equity) : '0' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="profitPercent" :label="$t('ManualReplenishment_RateOfReturn')">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.profitPercent ?formatNumberFractionDigits(scope.row.profitPercent)+ '%' : '0' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="depositPercent" :label="$t('ManualReplenishment_PrepaymentRatio')">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.depositPercent ?formatNumberFractionDigits(scope.row.depositPercent)  + '%' : '0' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="standardisation" :label="$t('ManualReplenishment_CopyRatio')">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.standardisation + '%' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="maxVolume" :label="$t('ManualReplenishment_MaxLot1')">

                    </el-table-column>
                    <!-- <el-table-column prop="maxDrawdown" :label="$t('ManualReplenishment_DrawdownValue')">
                    </el-table-column>
                    <el-table-column prop="takeProfit" :label="$t('ManualReplenishment_StopProfit')">
                    </el-table-column> -->
                </el-table>
            </div>

            <div v-if="mobileFlag" class="refillDetailMobile">
                <div class="refillDetail_title">
                    <el-breadcrumb separator-class="el-icon-arrow-left">
                        <el-breadcrumb-item class="pageTitle" :to="{ path: '/MyAccount' }">{{ refillMainAccount
                        }}</el-breadcrumb-item>
                        <el-breadcrumb-item class="pageTitle">{{ refillSubAccount }}</el-breadcrumb-item>
                    </el-breadcrumb>
                    <el-button class="confirmDialogBtn" @click="refresh()" icon="el-icon-refresh-right">
          {{
              $t("ManualReplenishment_refresh")
          }}</el-button>
                </div>
                <ul class="refillDetailMobileList">
                    <li>
                        <span class="refillDetailMobileLeft">
                            {{ $t('ManualReplenishment_Equity') }}
                        </span>
                        <span class="refillDetailMobileRight">
                            {{ refillDetailData.equity ?formatNumberFractionDigits(refillDetailData.equity)  : '0' }}
                        </span>
                    </li>
                    <li>
                        <span class="refillDetailMobileLeft">
                            {{ $t('ManualReplenishment_RateOfReturn') }}
                        </span>
                        <span class="refillDetailMobileRight">
                            {{ refillDetailData.profitPercent ? formatNumberFractionDigits(refillDetailData.profitPercent) + '%' : '' }}
                        </span>
                    </li>
                    <li>
                        <span class="refillDetailMobileLeft">
                            {{ $t('ManualReplenishment_PrepaymentRatio') }}
                        </span>
                        <span class="refillDetailMobileRight">
                            {{ refillDetailData.depositPercent? formatNumberFractionDigits(refillDetailData.depositPercent) + '%':0 }}
                        </span>
                    </li>
                    <li>
                        <span class="refillDetailMobileLeft">
                            {{ $t('ManualReplenishment_CopyRatio') }}
                        </span>
                        <span class="refillDetailMobileRight">
                            {{ refillDetailData.standardisation + '%' }}
                        </span>
                    </li>
                    <li>
                        <span class="refillDetailMobileLeft">
                            {{ $t('ManualReplenishment_MaxLot1') }}
                        </span>
                        <span class="refillDetailMobileRight">
                            {{ refillDetailData.maxVolume }}
                        </span>
                    </li>
                    <!-- <li>
                        <span class="refillDetailMobileLeft">
                            {{ $t('ManualReplenishment_DrawdownValue') }}
                        </span>
                        <span class="refillDetailMobileRight">
                            {{ refillDetailData.maxDrawdown }}
                        </span>
                    </li>
                    <li>
                        <span class="refillDetailMobileLeft">
                            {{ $t('ManualReplenishment_StopProfit') }}
                        </span>
                        <span class="refillDetailMobileRight">
                            {{ refillDetailData.takeProfit }}
                        </span>
                    </li> -->
                </ul>
            </div>
            <div class="refillBtns">
                <el-button @click="refillRe('', 're')"> {{ refillType == 1 ? $t("ManualReplenishment_SupplementaryOrder") :
                    $t("ManualReplenishment_CloseAPosition") }}</el-button>
                <el-button @click="refillRe()"> {{ $t('ManualReplenishment_Ignore') }}</el-button>
            </div>
            <div class="tableDiv" v-if="!mobileFlag">
                <p class="lostDetail_title">
                    {{ refillType == 1 ? $t('ManualReplenishment_TITLE1') : $t('ManualReplenishment_TITLE2')
                    }}
                </p>
                <el-table :data="detailList" border style="width: 100%" :select-on-indeterminate="checkAll"
                    @selection-change="handleSelectionChange">
                    <el-table-column :fixed="detailList.length !== 0 ? true : false" type="selection" width="50">
                    </el-table-column>
                    <!-- <el-table-column width="80" v-if="!mobileFlag" :fixed="detailList.length !== 0?true:false" prop="sortNum" :label="$t('MyFollowers_Number')">
                    </el-table-column> -->
                    <el-table-column width="110" prop="ticket" :label="$t('ManualReplenishment_MTOrder')">
                    </el-table-column>
                    <el-table-column width="180" prop="openTime" :label="$t('ManualReplenishment_openTime')">
                    </el-table-column>
                    <el-table-column prop="type" :label="$t('ManualReplenishment_Type')">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.type | formatStatus }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lots" :label="$t('ManualReplenishment_Size')">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.lots ? formatNumberFractionDigits((scope.row.lots / 100))  : '0' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="symbol" :label="$t('ManualReplenishment_Symbol')">
                    </el-table-column>
                   
                    <el-table-column prop="openPrice" :label="$t('ManualReplenishment_Price')">
                    </el-table-column>
                    <!-- <el-table-column  width="120" prop="currentPrice" :label="$t('ManualReplenishment_CurrentPrice')">
                    </el-table-column> -->
                    <el-table-column width="100" prop="commission" :label="$t('ManualReplenishment_Commission')">
                    </el-table-column>
                    <el-table-column prop="swap" :label="$t('ManualReplenishment_Storage')">
                    </el-table-column>
                    <el-table-column width="200" :fixed="detailList.length !== 0 ? 'right' : false" v-if="!mobileFlag"
                        :label="$t('MyFollowers_Operation')">
                        <template slot-scope="scope">
                            <!-- {{ scope.row.insTime | dateFilter }} -->
                            <div class="operateBtns">
                                <el-button @click="refillRe(scope.row, 're','one')" type="text" size="small">
                                    <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                                    {{ refillType == 1 ? $t("ManualReplenishment_SupplementaryOrder") :
                                        $t("ManualReplenishment_CloseAPosition") }}
                                </el-button>
                                <el-button @click="refillRe(scope.row,'','one')" type="text" size="small">
                                    <svg-icon class="greenIcon" icon-class="NeglectIcon"></svg-icon>
                                    {{ $t("ManualReplenishment_Ignore") }}
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="80" :fixed="detailList.length !== 0 ? 'right' : false" v-if="mobileFlag"
                        :label="$t('MyFollowers_Operation')">
                        <template slot-scope="scope">
                            <!-- {{ scope.row.insTime | dateFilter }} -->
                            <div class="operateBtns">
                                <el-popover placement="bottom-end" trigger="click">
                                    <el-button @click="refillRe(scope.row, 're','one')" type="text" size="small">
                                        <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                                        {{ refillType == 1 ? $t("ManualReplenishment_SupplementaryOrder") :
                                            $t("ManualReplenishment_CloseAPosition") }}
                                    </el-button>
                                    <el-button @click="refillRe(scope.row,'','one')" type="text" size="small">
                                        <svg-icon class="greenIcon" icon-class="NeglectIcon"></svg-icon>
                                        {{ $t("ManualReplenishment_Ignore") }}
                                    </el-button>
                                    <i slot="reference" class="mobileSetting el-icon-setting"></i>
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')" :pager-count="5"
                    :next-text="$t('MyFollowers_NexPage')" :current-page="currentPage" @current-change="handleCurrentChange"
                    @size-change="handleSizeChange" :page-sizes="[5, 10, 20, 50, 100]" :page-size="pageSize"
                    layout="prev, pager, next, sizes" :total="total">
                </el-pagination>
            </div>
            <p class="lostDetail_title" v-if="mobileFlag">
                    {{ refillType == 1 ? $t('ManualReplenishment_TITLE1') : $t('ManualReplenishment_TITLE2')
                    }}
                </p>
            <div class="mobileTableList" v-show="mobileFlag">
                
                <el-collapse accordion @change="collapseChange">
                    <el-collapse-item v-for="(item,index) in detailList" :key="index">
                    <template slot="title">
                      <div class="mobileTableCard" @click.stop="showCollapse()">
                        
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_MTOrder') }}
                          </p>
                            <p class="mobileTableContent" >
                              {{ item.ticket }}
                            </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_openTime') }}
                          </p>
                          <p class="mobileTableContent" >
                              {{  item.openTime  }} 
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_Type') }}
                          </p>
                          <p class="mobileTableContent" >
                            {{ item.type | formatStatus }}
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('ManualReplenishment_Size') }}
                          </p>
                          <p  class="mobileTableContent">
                            {{
                              item.lots ? formatNumberFractionDigits( (item.lots / 100)) : '0'
                            }}
                          </p>
                          </div>
                      </div>
                      <div class="mobileTableBtns" @click.stop="showCollapse()">
                        
                            
                            <el-button class="mobileBlueBtn" @click="refillRe(item, 're','one')" type="text" size="small">
                                <svg-icon class="greenIcon" icon-class="RefillIcon"></svg-icon>
                                {{ refillType == 1 ? $t("ManualReplenishment_SupplementaryOrder") :
                                    $t("ManualReplenishment_CloseAPosition") }}
                            </el-button>
                            <el-button class="mobileBlueBtn" @click="refillRe(item,'','one')" type="text" size="small">
                                <svg-icon class="greenIcon" icon-class="NeglectIcon"></svg-icon>
                                {{ $t("ManualReplenishment_Ignore") }}
                            </el-button>
                        
                      </div>
                    </template>
                    <div class="mobileTableCard" >
                        <div class="mobileTableItem">
                        <p class="mobileTableTitle" >
                            {{ $t('ManualReplenishment_Symbol') }}
                        </p>
                        <p class="mobileTableContent" >
                          {{  item.symbol  }} 
                        </p>
                        </div>
                        <div class="mobileTableItem">
                        <p class="mobileTableTitle" >
                            {{ $t('ManualReplenishment_Price') }}
                        </p>
                        <p class="mobileTableContent">
                          {{ item.openPrice }}
                        </p>
                        </div>
                        <div class="mobileTableItem">
                        <p class="mobileTableTitle" >
                            {{ $t('ManualReplenishment_Commission') }}
                        </p>
                          
                          <p class="mobileTableContent loginStatus" >
                              {{ item.commission }}
                          </p>
                        </div>
                        <div class="mobileTableItem">
                        <p class="mobileTableTitle" >
                            {{ $t('ManualReplenishment_Storage') }}
                        </p>
                        <p  class="mobileTableContent">
                            {{  item.swap  }} 
                        </p>
                        </div>
                    </div>
                </el-collapse-item>
                
                </el-collapse>
                <div class="x" v-if="up && detailList.length < total">
                      <i  class="el-icon-loading "></i>
                      {{ $t('loading') }}
                    </div>
                    <div class="x" v-if="!up && detailList.length >= total">
                      
                      {{ $t('noMoreData') }}
                    </div>
            </div>
            </div>
            </div>
        </div>
        <PopupTips :popupVisible='isPopupVisible' :icon="iconImg" :title="$t('MyFollowers_prompt')"
            :subContent="content1"
            @confirmPopupTips="confirmPopup" @cancelPopupTips="cancelPopup" ref="popupTipsRef" :content="infoTitle"
            :isHtml="isHtml" :btnType="2" />
        <!-- <PopupTips :popupVisible='isPopupVisibleRe' :icon="iconImg" :title="$t('MyFollowers_prompt')"
            @confirmPopupTips="confirmPopup" @cancelPopupTips="cancelPopup" ref="popupTipsRef" :content="infoTitleRe"
            :btnType="2" /> -->
    </div>
</template>

<script>
// @ is an alias to /src
import { concatParams, concatTableData } from "../../plugins/common.js";
import { mapState } from "vuex";
import PopupTips from '@/components/moudule/PopupTips.vue'
import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
  
  BScroll.use(Pullup)

export default {
    name: 'RefillDetail',
    computed: {
        ...mapState(["checkPortfolio", "mobileFlag", "refillMainAccount", "refillSubAccount", "refillType", "token","refillData"]),
    },
    components: {
        PopupTips
    },
    data() {
        return {
            tableData: [],
            detailList: [],
            currentPage: 1,
            total: 0,
            pageSize: 5,
            tableSearchForm: {
                Account: '',
                DateRange: []
            },
            checkAll: false,
            orderList: [],
            refillDetailData: {},
            isPopupVisible: false,
            iconImg: require('../../assets/images/NotifyWarning.svg'),
            infoTitle: '',
            infoTitleRe: '',
            checkRow: {},
            checkSign: '',
            isHtml: false,
            content1:'',
            up:false,
            firstOpen:false
        }
    },
    filters: {
        formatStatus: function (e) {
            //console.log(e)
            switch (e) {
                case 0:
                    return 'Buy'
                case 1:
                    return 'Sell'
                case 2:
                    return 'BuyLimit'
                case 3:
                    return 'SellLimit'
                case 4:
                    return 'BuyStop'
                case 5:
                    return 'SellStop'
                case 6:
                    return 'Balance'
                case 7:
                    return 'Credit'
                default:
                    break;
            }
        }
    },
    created() {
        this.getData()
        this.getDetailList(this.currentPage, this.pageSize)
    },
    methods: {
        formatNumberFractionDigits,
        collapseChange(){
            this.$nextTick(() => {
                    if(!this.firstOpen){
                    this.scroll.maxScrollY = this.scroll.maxScrollY - 150
                    this.firstOpen = true
                    }else{
                    this.scroll.maxScrollY = this.scroll.maxScrollY + 150
                    this.firstOpen = false
                    }
                });
            
        },
        scrollFn(){
            this.$nextTick(() => {
            
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.bscroll, {
                        click: true,
                        scrollY: true,
                        probeType: 3
                    });
                }else{
                    this.scroll.refresh();
                }
                
                //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
                this.scroll.on('touchEnd', (pos) => {
                    //上拉加载 总高度>下拉的高度+10 触发加载更多
                    if(this.scroll.maxScrollY>pos.y + 50){
                        // console.log("加载更多")
                        this.up=true;
                        setTimeout(()=>{
                        //使用refresh 方法 来更新scroll  解决无法滚动的问题
                            if(this.detailList.length < this.total){
                                this.currentPage = this.currentPage + 1
                                this.getDetailList(this.currentPage,this.pageSize,'noRefresh')
                                this.scroll.refresh();
                                this.up=false;
                            }else{
                                this.up=false;
                            }
                            
                    },1000) 
                    
                    }
                    // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
                })
            
                // console.log(this.scroll.maxScrollY)
            });
        },
        // 获取顶部信息
        getData() {
            
            // console.log(this.tableSearchForm.length)
            var params = {
                account: this.refillSubAccount
            }
            this.$axios.get('/Api/Recon/AccountInfo' + concatParams(params)).then((res) => {
                let dt = res.data
                if (dt.isSuccess) {
                    this.tableData.push(dt.result)
                    this.refillDetailData = dt.result
                }
            })
        },
        // 获取明细table数据
        getDetailList(pageIndex, pageSize,tag) {
            if(!this.mobileFlag || tag !== 'noRefresh'){
                this.detailList = [];

            }
            // console.log(this.tableSearchForm.length)
            var params = {
                mainAccount: this.refillMainAccount,
                subAccount: this.refillSubAccount,
                type: this.refillType,
                pageIndex: pageIndex,
                pageSize: pageSize
            }
            this.$axios.get('/Api/Recon/OrderListForRecon' + concatParams(params)).then((res) => {
                let dt = res.data
                if (dt.isSuccess) {
                    this.total = dt.result.total
                    // this.detailList = concatTableData(dt.result.pageList, dt.result.total, dt.result.pageIndex, this.pageSize)
                    if(this.mobileFlag && tag == 'noRefresh'){
                        dt.result.pageList.forEach((item) =>{
                            this.detailList.push(item)
                        })
                        
                        
                    }else{
                        this.detailList = concatTableData(
                            dt.result.pageList,
                            dt.result.total,
                            dt.result.pageIndex,
                            this.pageSize
                        );
                    }
                    
                    if(this.mobileFlag){
                        this.scrollFn()
                    }
                }
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getDetailList(this.currentPage, this.pageSize)
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getDetailList(this.currentPage, this.pageSize)
        },
        // 刷新
        refresh() {
            this.getData()
            this.currentPage = 1
            this.getDetailList(this.currentPage, this.pageSize)
        },
        // 多选选中
        handleSelectionChange(val) {
            this.orderList = []
            val.forEach(element => {
                this.orderList.push(element.ticket)
            });
            if (val.length == this.detailList.length) {
                this.checkAll = true
            } else {
                this.checkAll = false
            }

            // console.log(this.checkAll)
            // this.multipleSelection = val;
        },
        refillRe(row, sign, tag) {
            this.checkRow = row
            this.checkSign = sign
            if (row || this.orderList.length !== 0) {
                if (sign == 're') {
                    this.infoTitle = this.checkAll && tag !== 'one' ? (this.refillType == 1 ? this.$t("ManualReplenishment_ComfirmAll") : this.$t("ManualReplenishment_ComfirmAll2")) : (this.refillType == 1 ? this.$t("ManualReplenishment_Comfirm") : this.$t("ManualReplenishment_Comfirm2"))
                    this.content1 = this.checkAll && tag !== 'one' ? (this.refillType == 1 ? this.$t("ManualReplenishment_ComfirmAll_bottom") : this.$t("ManualReplenishment_ComfirmAll2_bottom")) : ''

                } else {
                    this.infoTitle = this.checkAll && tag !== 'one' ? this.$t("ManualReplenishment_ignoreAll") : this.$t("ManualReplenishment_ignoreOne")
                    this.content1 = this.checkAll && tag !== 'one' ? this.$t("ManualReplenishment_ignoreAll_bottom") : ''
                    
                }
                if (this.checkAll && tag !== 'one' ) {
                    this.isHtml = true
                } else {
                    this.isHtml = false
                }
                this.isPopupVisible = true

            } else if (this.orderList.length == 0) {
                this.$notify.warning({title: this.$t('MyFollowers_prompt'), message:this.$t('refillSelectNullTips')});

            }

        },
        cancelPopup(val) {
            this.isPopupVisible = val
        },
        confirmPopup(val) {
            this.isPopupVisible = val

            if (this.checkSign == 're') {
                this.refill(this.checkRow)
            } else {
                this.ignore(this.checkRow)
            }
        },
        refill(row) {
            // this.$confirm(
            //     infoTitle,
            //     this.$t("MyFollowers_prompt"),
            //     {
            //         confirmButtonText: this.$t("MyFollowers_confirm"),
            //         cancelButtonText: this.$t("MyFollowers_Cancel"),
            //         type: "warning",
            //         dangerouslyUseHTMLString: true,
            //         customClass: this.checkAll ? 'confirmMessage' : ''
            //     }
            // ).then(() => {
            this.fullscreenLoading = true;
            var orderList = []

            if (row) {
                orderList.push(row.ticket)
            }
            var params = {
                orderList: row ? orderList : this.orderList,
                reconOrderType: this.refillType == 1 ? 1 : 3,
                followUnique: this.refillData.followUnique,
                mainUnique: this.refillData.mainUnique,
            };
            this.$axios
                .post("/Api/Recon/ReconOrders", params)
                .then((res) => {
                    let dt = res.data;
                    if (dt.isSuccess) {
                        
                            this.$notify.success({title: this.$t('nofity_success'), message:this.$t('MyFollowers_operateSuccess1')});


                       

                        this.refresh();
                    } else {
                        this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                    }
                })
                .finally(() => {
                    this.fullscreenLoading = false;
                });
            // });


        },
        ignore(row) {
            // if (row || this.orderList.length !== 0) {
            //     // this.$confirm(
            //     //     infoTitle,
            //     //     this.$t("MyFollowers_prompt"),
            //     //     {
            //     //         confirmButtonText: this.$t("MyFollowers_confirm"),
            //     //         cancelButtonText: this.$t("MyFollowers_Cancel"),
            //     //         type: "warning",
            //     //         dangerouslyUseHTMLString: true,
            //     //         customClass: this.checkAll ? 'confirmMessage' : ''
            //     //     }
            //     // ).then(() => {
            this.fullscreenLoading = true;
            var orderList = []
            if (row) {
                orderList.push(row.ticket)
            }
            var params = {
                tickets: row ? orderList : this.orderList,
                type: this.refillType == 1 ? 1 : 2,
                account: this.refillSubAccount,
                mainAccount: this.refillMainAccount,
            };
            this.$axios
                .post("/Api/Recon/IgnoreOrders", params)
                .then((res) => {
                    let dt = res.data;
                    if (dt.isSuccess) {
                            this.$notify.success({title: this.$t('nofity_success'), message:dt.result});
                            this.refresh();
                    } else {
                        this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                    }
                })
                .finally(() => {
                    this.fullscreenLoading = false;
                });
            // });
            // } else if (this.orderList.length == 0) {
                // this.$notify.warning({title: this.$t('MyFollowers_prompt'), message:this.$t('refillSelectNullTips')});

            
            // }
        },
        back(){
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
.refillDetail {
    .closeTips{
        text-align: left;
        color: #E35972;
        margin: 10px 0;
        font-size: 14px;
    }
    .pageTitle {
        font-family: 'PingFangSC-Semibold';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        /* identical to box height */
        /* 正文黑 */
        color: #212121;
        margin-bottom: 0;
    }

    .refillDetail_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        p {
            font-family: 'PingFangSC-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 34px;
            color: #4D71FF;
            margin: 0 0 10px 30px;
            cursor: pointer;
        }
    }

    .refillBtns {
        margin: 30px 0 20px 0;

        .el-button {
            background: #4D71FF;
            /* 按钮阴影 */

            box-shadow: 0px 4px 7px rgba(227, 57, 53, 0.07);
            border-radius: 8px;
            font-family: 'PingFangSC-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            padding: 8px 15px;
            color: #FFFFFF;
        }
    }

    .lostDetail_title {
        font-family: 'PingFangSC-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 8px 0;
        /* 正文黑 */

        color: #212121;

    }

    .operateBtns {
        padding: 0;
    }
}





@media screen and (max-width:769px) {
    .home {
        .content {
            .pageCommon {
                .pageTitle {
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;

                    /* 正文黑 */

                    color: #212121;
                }
            }
        }
    }

    .refillDetailMobile {
        border: 1px solid #E5E5E5;
        border-radius: 8px;
        padding: 20px;
        background: #FFFFFF;

        .refillDetail_title {
            p {
                margin-bottom: 0;
            }
        }

        .refillDetailMobileList {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 6px 10px;
                background: #F8F8F8;
                margin: 10px 0;
                display: flex;
                flex-direction: row;

                .refillDetailMobileLeft {
                    width: 30%;
                    font-family: 'PingFangSC-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height */


                    color: #9E9E9E;
                }

                .refillDetailMobileRight {
                    width: 60%;
                    font-family: 'PingFangSC-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height */


                    color: #323232;
                }
            }

        }


    }
    .refillDetail{
        .refillBtns {
            margin: 16px 0 20px 0;

            .el-button {
                background: transparent;
                /* 按钮阴影 */
                box-shadow: 0px 4px 7px rgba(227, 57, 53, 0.07);
                border-radius: 8px;
                font-family: 'PingFangSC-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                padding: 8px 14px;
                color: #4D71FF;
                border-color: #4D71FF;
            }
        }
        .lostDetail_title{
            color: #0B205D;
        }
    }
    

    .enHtml {
        .refillDetailMobile {
            .refillDetailMobileList {

                li {
                    .refillDetailMobileLeft {
                        width: 50%;
                    }

                    .refillDetailMobileRight {
                        width: 50%;
                    }
                }

            }


        }
    }
  
    .confirmDialogBtn{
        padding: 8px ;
        font-size: 12px;
        line-height: 15px;
      }
  

}
</style>
